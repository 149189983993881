import jwt from 'jsonwebtoken';

const TOKEN_NAME = 'jwt-token';

export function isAuthenticated() {
  try {
    let _ = jwt.verify(localStorage.getItem(TOKEN_NAME), process.env.REACT_APP_JWT_SECRET); // eslint-disable-line no-unused-vars
    return true;
  } catch (e) {
    return false;
  }
}

export function signToken(email, callback) {
  jwt.sign({ email }, process.env.REACT_APP_JWT_SECRET, { expiresIn: 60 * 60 * 24 * 365 }, (error, token) => {
    if (error) {
      callback(error);
    } else {
      localStorage.setItem(TOKEN_NAME, token);
      callback();
    }
  });
}