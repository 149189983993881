import React from 'react';

import MainContent from './MainContent';
import DashboardWrapper from './DashboardWrapper';

export default function Dashboard() {
  return (
    <DashboardWrapper>
      <MainContent />
    </DashboardWrapper>
  );
}