import React from 'react';
import { useSelector } from 'react-redux';
import { Layout, Typography, Divider } from 'antd';
import styled from 'styled-components';

const Title = styled(Typography.Title)`
  text-align: center;
  font-size: 24px;
`;

const PaddedLayout = styled(Layout)`
  padding: 15px;
`;

export default function LetterView() {
  const letter = useSelector((state) => state.letter);

  return (
    <PaddedLayout>
      <Title>{letter.title}</Title>
      <Divider />
      {letter.body.split("\\n").map((word) => {
        return (
          <>
            <Typography.Text>{word}</Typography.Text>
            <br />
          </>
        );
      })}
    </PaddedLayout>
  );
}