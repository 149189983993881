import { combineReducers, createStore } from 'redux';
import { firestoreReducer } from 'redux-firestore';

import letter from './letter';

const rootReducer = combineReducers({
  letter,
  firestore: firestoreReducer
});

export default createStore(rootReducer);