import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Layout } from 'antd';
import styled from 'styled-components';
import LetterView from './LetterView';
import { setLetter } from '../redux/letter';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';

const PeriwinkleLayout = styled(Layout)`
  padding: 50px;
  background-color: #ccccff;
  height: 100vh;
`;

const MarginSider = styled(Layout.Sider)`
  padding: 30px;
  margin-right: 30px;
`;

const SelectedItem = styled(List.Item)`
  background-color: rgba(204, 204, 255, 0.4);
  padding: 10px;
`;

const Item = styled(List.Item)`
  padding: 10px;
  cursor: pointer;
`;

export default function LettersListView() {
  useFirestoreConnect({
    collection: 'letters',
    orderBy: 'createdAt'
  });

  const letters = useSelector((state) => state.firestore.ordered.letters);
  const [selectedItem, setSelectedItem] = useState('');

  const dispatch = useDispatch();

  function onSelectItem(item) {
    setSelectedItem(item.title);
    dispatch(setLetter(item));
  }

  useEffect(() => {
    if (isLoaded(letters)) {
      onSelectItem(letters[letters.length - 1]);
    }
  }, [letters]);

  function ellipsify(data) {
    return data.substring(0, 75) + '...';
  }

  return (
    <PeriwinkleLayout>
      <MarginSider width={300}>
        <List
          dataSource={letters}
          renderItem={item => 
            item.title === selectedItem ? (
              <SelectedItem>
                <List.Item.Meta
                  title={item.title}
                  description={ellipsify(item.body)}
                />
              </SelectedItem>
            ) : (
              <Item onClick={() => onSelectItem(item)}>
                <List.Item.Meta
                  title={item.title}
                  description={ellipsify(item.body)}
                />
              </Item>
            )}
        />
      </MarginSider>
      <LetterView />
    </PeriwinkleLayout>
  );
}