import React from 'react';
import { 
  Layout,
  Card,
  Form,
  Button,
  Input
} from 'antd';
import styled from 'styled-components';
import { useHistory, Redirect } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';

import { isAuthenticated, signToken } from '../utils/jwt';
import DashboardWrapper from './DashboardWrapper';

const PeriwinkleLayout = styled(Layout)`
  background-color: #ccccff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ShadowCard = styled(Card)`
  width: 50vw;
  height: 50vh;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
`;

const ConstrainedForm = styled(Form)`
  width: 40vw;
  min-width: 40vw;
  padding: 10px;
`;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
}

export default function Login() {
  const firebase = useFirebase();
  const history = useHistory();

  function onSubmit(values) {
    const { email, password } = values;
    firebase.login({ email, password })
      .then(() => {
        signToken(email, (err) => {
          if (err) {
            alert(err);
          } else {
            history.push('/');
          }
        });
      })
      .catch((err) => alert(err));
  }

  return isAuthenticated() ? (
      <Redirect to="/" />
    ) : (
      <DashboardWrapper>
        <PeriwinkleLayout>
          <ShadowCard title="Login">
            <ConstrainedForm {...layout} name="login" onFinish={onSubmit}>
              <Form.Item 
                label="Email" 
                name="email" 
                rules={[
                  {
                    required: true,
                    message: 'Please enter an email'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item 
                label="Password" 
                name="password" 
                rules={[
                  {
                    required: true,
                    message: 'Please enter a password'
                  }
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </ConstrainedForm>
          </ShadowCard>
        </PeriwinkleLayout>
      </DashboardWrapper>
    );
}