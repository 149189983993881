import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';

const { Header } = Layout;

const Title = styled.h1`
  font-size: 36px;
  text-align: center;
`;

export default function DashboardWrapper({ children }) {
  return (
    <Layout>
      <Header>
        <Title>I love you Jasmine 💘</Title>
      </Header>
      {children}
    </Layout>
  );
}