import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider as StoreProvider } from 'react-redux';
import firebase from 'firebase';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';

import './App.less';
import { initializeApp } from './persistence/firebase';
import Dashboard from './components/Dashboard';
import store from './redux/store';
import ProtectedRoute from './utils/ProtectedRoute';
import Login from './components/Login';

initializeApp();

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

function App() {
  return (
    <StoreProvider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps} >
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={Login} />
            <ProtectedRoute exact path="/" component={Dashboard} />
            <Route component={Dashboard} />
          </Switch>
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </StoreProvider>
  );
}

export default App;
