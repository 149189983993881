const SET_LETTER = 'SET_LETTER';

export function setLetter(letter) {
  return {
    type: SET_LETTER,
    payload: letter
  }
}

const initialState = {
  title: 'This is a letter title',
  body: 'I love you so much Jasmine. You are the love of my life and I love you so much.'
};

export default function letter(state = initialState, action) {
  switch (action.type) {
    case SET_LETTER:
      return {
        title: action.payload.title,
        body: action.payload.body
      }
    default:
      return state;
  }
}